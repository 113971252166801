import { Hero as BaseHero } from '../../../../components';
import { Icon } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import { Text } from '../../../../theme/components/Typography';
import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { size } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const HeroIconsWrapper = styled.div`
  bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  color: ${colors.p04};

  svg {
    ${size('2rem')};

    &:first-of-type {
      ${size('1.5rem')};

      display: none;
    }
  }

  ${mq.tablet(css`
    bottom: 8rem;

    svg {
      &:first-of-type {
        display: block;
      }
    }
  `)};
`;

export const Hero = props => {
  const { text: intro } = props;

  return (
    <BaseHero backgroundImage="/img/hero/hero-bkg.jpg" colWidth={12} withLogo>
      <Text center h4 large mt={{ sm: 2.75, xs: 1.5 }} n01 semibold>
        <RichText content={intro} />
      </Text>
      <HeroIconsWrapper>
        <Icon icon="mouse" />
        <Icon icon="arrowDown" />
      </HeroIconsWrapper>
    </BaseHero>
  );
};

Hero.propTypes = {
  backgroundImage: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.any,
  withLogo: PropTypes.bool
};
