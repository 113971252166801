import { AnimatedGroup, SectionHeading } from '../../../../components';
import { Col, Grid, Row, Section } from '../../../../theme/components';
import { mq } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Img = styled.img`
  width: 100%;
  max-width: 11.25rem;
  height: auto;
  max-height: 7.5rem;
  vertical-align: middle;

  ${mq.tablet(css`
    width: auto;
  `)}
`;

const List = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: -2.5rem;
  text-align: center;

  & > div {
    & > div {
      display: inline-block;
      padding: 2rem;
      width: 50%;

      ${mq.tablet(css`
        padding: 2.5rem;
        width: auto;
      `)}
    }
  }
`;

export const Partners = props => {
  const { items, ...heading } = props;

  return (
    <Section>
      <SectionHeading {...heading} colWidth={12} />
      <Grid>
        <Row>
          <Col xs={12}>
            <List>
              <AnimatedGroup row={false} stagger={0.1}>
                {items.map(({ image: { url } }, index) => (
                  <div key={index}>
                    <Img src={url} />
                  </div>
                ))}
              </AnimatedGroup>
            </List>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Partners.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string
      })
    })
  ),
  preHeading: PropTypes.string
};
