import { Awards, Features, Financing, Hero, News, Partners } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Homepage = props => {
  const {
    pageContext: { articles, document, common }
  } = props;
  const { features } = document;

  const hero = extractFromCamelCase(document, 'hero');
  const news = extractFromCamelCase(document, 'news');
  const financing = extractFromCamelCase(common, 'financing');
  const awards = extractFromCamelCase(document, 'awardsAndMentions');
  const partners = extractFromCamelCase(document, 'partnersAndClients');

  return (
    <>
      <Hero {...hero} />
      <Financing {...financing} />
      <Features features={features} />
      <News {...news} articles={articles} />
      <Awards {...awards} />
      <Partners {...partners} />
    </>
  );
};

Homepage.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Homepage, { withScrollBehaviour: true, withTestimonials: true });
