import { AnimatedEl, AnimatedGroup, ArticleList, LocalLink, SectionHeading } from '../../../../components';
import { Button, Grid, Section } from '../../../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const News = props => {
  const { articles, buttonLabel, ...sectionHeading } = props;

  return (
    <Section>
      <SectionHeading {...sectionHeading} />
      <ArticleList articles={articles} inFrontpage />
      <Grid mt={4.5}>
        <AnimatedGroup>
          <AnimatedEl center col xs={12}>
            <LocalLink href="/news">
              <Button as="span">{buttonLabel}</Button>
            </LocalLink>
          </AnimatedEl>
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

News.propTypes = {
  articles: PropTypes.array,
  buttonLabel: PropTypes.string,
  heading: PropTypes.string,
  preHeading: PropTypes.string
};
