import { AnimatedEl, AnimatedGroup } from '../../../../components';
import { Section as BaseSection, Grid } from '../../../../theme/components';
import { SectionContent } from '../../../../components/SectionContent/SectionContent';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Section = styled(BaseSection).attrs({
  id: 'features',
  smallPadding: true
})`
  .hst-rich-text-image-link {
    display: inline-block;
    height: 4rem;
    margin-top: 1rem;

    img {
      height: 100%;
      width: auto;
    }

    & + & {
      margin-left: 1rem;
    }

    &:hover {
      opacity: 0.75;
    }
  }
`;

export const Features = ({ features }) => {
  return (
    <Section>
      <Grid>
        {features.map(({ image: { url: image }, ...content }, index) => (
          <AnimatedGroup
            key={index}
            middle="xs"
            mt={{ md: index !== 0 ? 12 : 0, xs: index !== 0 ? 6 : 0 }}
            reverse={index % 2 !== 0}
          >
            <AnimatedEl md={7} mdOffset={index % 2 !== 0 ? 1 : null} textAlign={{ md: 'left', xs: 'center' }} xs={12}>
              <img src={image} style={{ maxWidth: '100%' }} />
            </AnimatedEl>
            <AnimatedEl
              animation={{ from: index % 2 === 0 ? 'right' : 'left' }}
              md={4}
              mdOffset={index % 2 === 0 ? 1 : null}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <SectionContent {...content} textProps={{ className: 'hst-features-rich-text' }} />
            </AnimatedEl>
          </AnimatedGroup>
        ))}
      </Grid>
    </Section>
  );
};

Features.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonUrl: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string
      }),
      text: PropTypes.array
    })
  )
};
