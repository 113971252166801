import { colors, fonts } from '../../../../theme';
import { ease, mq, transitions } from 'styled-gen';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

const FinancingBox = styled.div`
  ${transitions('all', '0.5s', ease.inOutCubic)};

  bottom: 0;
  background-color: ${colors.n01};
  /* box-shadow: 0 0 2rem ${rgba(colors.n08, 0.16)}; */
  left: 0;
  padding: 1rem;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 10;

  ${mq.tablet(css`
    border-radius: 0.5rem;
    display: inline-block;
    margin-left: auto;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    left: auto;
    text-align: left;
    transform: translateY(65%);
    width: initial;
  `)}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      transform: translateY(120%);

      ${mq.tablet(css`
        opacity: 0;
        visibility: hidden;
        transform: translate(25%, 65%);
      `)}
    `}
`;

const FinancingHeading = styled.h6`
  color: ${colors.n05};
  font-size: ${fonts.size('xs')};
  font-weight: ${fonts.weights.semibold};
  margin-bottom: 1rem;
  text-align: left;
`;

const FinancingLogo = styled.img`
  display: inline-block;
  height: 1.5rem;
  width: auto;

  ${mq.tablet(css`
    height: 2.5rem;
  `)};
`;

const FinancingLogoLink = styled.a`
  display: inline-block;

  & + & {
    margin-left: 2rem;
  }
`;

const FinancingWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 0;
`;

export { FinancingBox, FinancingHeading, FinancingLogo, FinancingLogoLink, FinancingWrapper };
