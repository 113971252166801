import { Col, Grid, Row } from '../../../../theme/components';
import { FinancingBox, FinancingHeading, FinancingLogo, FinancingLogoLink, FinancingWrapper } from './Financing.style';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Financing extends Component {
  static propTypes = {
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        logo: PropTypes.shape({
          url: PropTypes.string
        })
      })
    ),
    heading: PropTypes.string
  };

  state = {
    isScrolling: false
  };

  topbarRef = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const isScrolling = window.scrollY > 100;

    if (this.state.isScrolling !== isScrolling) {
      return this.setState({ isScrolling });
    }
  };

  render() {
    const { isScrolling } = this.state;
    const { entities, heading } = this.props;

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <FinancingWrapper>
              <FinancingBox isVisible={!isScrolling}>
                <FinancingHeading>{heading}</FinancingHeading>
                {entities.map(({ logo: { url } }, index) => (
                  <FinancingLogoLink href="about#projects" key={index}>
                    <FinancingLogo src={url} />
                  </FinancingLogoLink>
                ))}
              </FinancingBox>
            </FinancingWrapper>
          </Col>
        </Row>
      </Grid>
    );
  }
}
