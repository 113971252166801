import { AnimatedGroup, SectionHeading } from '../../../../components';
import { Col, Grid, Row, Section } from '../../../../theme/components';
import { mq } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Img = styled.img`
  width: auto;
  max-width: 11.25rem;
  height: auto;
  max-height: 3.125rem;

  ${mq.phone(css`
    width: 100%;
  `)}
`;

const List = styled.div`
  & > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -2rem;
    text-align: center;
    width: 100%;

    ${mq.tabletLandscape(css`
      justify-content: space-around;
    `)}

    & > div {
      padding: 0 0.75rem 2rem;
      width: 50%;

      ${mq.tablet(css`
        width: 33%;
      `)}

      ${mq.tabletLandscape(css`
        padding: 0 0.5rem 2rem;
        width: auto;
      `)}
    }
  }
`;

export const Awards = props => {
  const { heading, items } = props;

  return (
    <Section>
      <SectionHeading preHeading={heading} preHeadingProps={{ fillColor: 'n035' }} />
      <Grid>
        <Row>
          <Col xs={12}>
            <List>
              <AnimatedGroup row={false} stagger={0.1}>
                {items.map(({ image: { url } }, index) => (
                  <div key={index}>
                    <Img src={url} />
                  </div>
                ))}
              </AnimatedGroup>
            </List>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Awards.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string
      })
    })
  )
};
